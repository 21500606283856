h1 {
    font-size: 40px;
    color: rgb(233, 9, 9);
    text-align: center;
   
    /* justify-content: center; */
  }
  
  .cardContainer {
    display: flex;
     margin-top: 10px;
    flex-wrap: wrap;
    gap: 120px;
    margin-left: 28px;
    margin-bottom: 160px;
    
  }
  
 
  
  /* .subtitle{
      font-size: 50px;
      font-weight: 600;
      color: #110202ce;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  } */
  /* .portfolio{
      padding: 20px 0;
  } */
  .work {
    height: 15rem;
    width: 12rem;
  }
  .work img {
    width: 170%;
    margin-bottom: 30px 20px;
    border-radius: 10px;
  }
   /* .layer {
    width: 200%;
    margin-top: 80px ;
    margin-left: 5px;
    margin-bottom: 0px; 
    
     margin-left: 20px;
       margin-right: 50px; 
   }
  .layer h3 {
    font-size: 20px;
    font-weight: 500px;
    text-align: center;
  }
  .layer p {
    font-size: 15px;
    font-weight: 300;
    margin-top: -15px;
    text-align: center;
  }  */

  .work:hover img {
      transform: scale(1.01);
      
  }
  /* .work:hover .layer{
      height: 100%;
      transform: scale(1.05);
      
      
  }
   */

  