.raw{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1px 10%;
    background-color: rgb(84 84 84);
}
.leftside{
  margin-left: -20px;
 
}
.rightside{
 flex-basis: 52%;

}

address{
    font-weight: bold;
    font-size: 15px;
}
.responsivemap{
     overflow: hidden;
     display: block; 
     border-radius: 25px;
     margin-left: -90px;
     box-sizing: border-box;
     position: relative;
    margin-top: 18px;
    margin-bottom: -8px;
 
}
.boxes {
  box-sizing: border-box;
  height: 20px;
}
  .rightside img {
   height: 200px;
   margin-top: 50px;
   margin-left: 80%;
  
} 
.rightside address {
    color: white;
    margin-top: -60px;
   margin-left: 72%;
   margin-right: -120px;
} 

.socialicons{
    margin-top: 40px;  
    margin-left: 10px;

}
.socialicons p{
    color: white;
   
}
.socialicons  i{   
    font-size: 20px;

}
.socialicons a {
    text-decoration: none;
    font-size: 20px;
    margin-right: 15px;
    color: white;
    display: inline-block;
    transition: trasform 0.5s;
    margin-left: 13px;
    
}
.socialicons a:hover{
    color: hwb(341 42% 7%) ;
    transform: translateY(-5PX);
}

.followicon  i {

    padding: 6px 5%;
    font-size: 35px;
 
}
.socialicons h5 {
    color: white;
    margin-left: 40px;
    margin-top: 80px;
    font-size: 25px;
}
.policy{
 
  text-align: center;
  margin-top: -55%;
  margin-left: -11px;
  font-size: 20px;
  left: 0;


}
 h3{
   text-align: center;
   color: white;
 margin-left: 36%;
 margin-top: 60px;
 font-size: 17px;

}


