
footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 20px 20px 10px 20px;
  }
  .container { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
.notfound img {
     width: 50%;
  } 
  .notfound p{
    text-align: center;
   
  }
h2{
    text-align: center;
    /* font-size: 30px; */
  }
  
  @media (max-width: 1520px) {
    footer .container {
      min-width: 100%;
      padding: 0 20px;
    }
  }
  @media (max-width: 530px) {
    footer .container .banner:first-child {
      flex-direction: column;
      gap: 15px;
    }
    footer .container .banner:first-child .left,
    footer .container .banner .right {
      text-align: center;
    }
    footer .container .banner {
      flex-direction: column;
      text-align: center;
      gap: 7px;
    }
  }
  