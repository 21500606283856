/* image container  */
.container {
  box-sizing: border-box;
  height: 30%;
  margin-top: 20px;
}

.container img {
  width: 100%;
  height: 60vh;
}

/* help center and image section  */
.hepimg {
  width: 30%;
  margin-left: 7%;
}

.hepimg img {
  height: auto;
  width: 100%;
}

.helptext {
  margin: 50px 40px;
  margin-left: 30%;
}

.helptext h5 {
  font-size: 40px;
}

.helptext p {
  margin-top: 30px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .helptext {
    margin: 50px 15px;
  }

  .helptext h5 {
    font-size: 40px;
  }

  .helptext p {
    font-size: 18px;
  }
}

/* contact box two side  */
.reservation .container .bannerbox {
  display: flex;
  gap: 10px;
  margin-top: -200px;
  width: 100%;
  padding: 30px;
}

.reservation .container .bannerbox .reservation_form_box {
  width: 85%;
  height: 40vh;
  background: #f0f0f4;
  padding: 30px;
  border-radius: 40px;
  box-shadow: 12px 12px 10px 2px;
  margin-left: 50px;
  
}

.reservation .container .bannerbox .reservation_form_box .contactbox h1 {
  font-style: bold;
  font-family: 'Times New Roman', Times, serif;
}

.reservation .container .bannerbox .reservation_form_box .contactbox p {
  text-align: left;
  font-size: 25px;
  font-style: bold;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.reservation .container .bannerbox .reservation_form_box .contactbox i {
  font-size: 30px;
  padding: 9px;
  margin-top: -15px;
}

.reservation .container .bannerbox .reservation_form_box .contactbox a {
  font-size: 25px;
  text-decoration: none;
  color: black;
}

@media (max-width: 768px) {
  .reservation .container .bannerbox .reservation_form_box .contactbox p,
  .reservation .container .bannerbox .reservation_form_box .contactbox a {
    font-size: 20px;
  }

  .reservation .container .bannerbox {
    padding: 20px;
  }
}

/* feedback form  */
.starbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 50px;
  flex-direction: column;
}

.star > span {
  cursor: pointer;
  padding: 30px;
}

.selected {
  color: yellow;
}

.feedbackform form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedbackform button {
  border: 1px solid rgb(42, 186, 44);
  padding: 8px 36px;
  border-radius: 30px;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 23px;
  color: #111111;
  background-color: #c7ebc8;
  margin-top: 30px;
  text-decoration: none;
  transition-duration: 0.4s;
}

.feedbackform button:hover {
  background-color: #04aa6d; /* Green */
  color: white;
}

/* contact form booking page  */
.reservation {
  display: flex;
  min-height: 75vh;
  padding: 30px 20px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
 
}

.reservation .container {
  max-width: 1500px;
  min-width: 1500px;
  display: flex;
  margin-top: -50px;

 
}

.reservation .container .banner {
  width: 50%;
  padding: 80px;
 margin-left: 30px;
}

.reservation .container .banner .reservation_form_box {
  width: 85%;
  height: 85%;
  background: #fff;
  padding: 20px;
  box-shadow: -1px 0px 37px 0px rgba(152, 87, 191, 0.75);
  -webkit-box-shadow: -1px 0px 37px 0px rgba(152, 87, 191, 0.75);
  -moz-box-shadow: -1px 0px 37px 0px rgba(152, 87, 191, 0.75);
}

.reservation .container .banner .reservation_form_box form div {
  display: flex;
  gap: 20px;
  margin-bottom: 28px;
}

.reservation .container .banner .reservation_form_box form div input {
  flex: 1;
  padding: 5px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #333;
  background: transparent;
}

.reservation .container .banner .reservation_form_box form input[type="email"] {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #555;
  background: transparent;
  margin-bottom: 2px;
}

.reservation .container .banner .reservation_form_box form input[type="text"] {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  font-weight: 300;
  border: none;
  border-bottom: 1px solid #333;
  background: transparent;
}

.reservation .container .banner .reservation_form_box form div input:focus,
.reservation .container .banner .reservation_form_box form input[type="number"]:focus,
.reservation .container .banner .reservation_form_box form input[type="email"]:focus {
  outline: none;
  border-bottom: 2px solid skyblue;
}

.reservation .container .banner .reservation_form_box form div input::placeholder {
  color: #222;
  font-size: 20px;
}

.reservation .container .banner .reservation_form_box h1 {
  font-weight: 200;
  font-size: 2.75rem;
  color: #111;
  text-align: center;
}

.reservation .container .banner .reservation_form_box p {
  color: #333;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
}

.reservation .container .banner:first-child {
  display: flex;
  justify-content: center;
}

.reservation .container .banner img {
  width: 85%;
  height: 85%;
}

.reservation .container .banner .reservation_form_box form button {
  border: 1px solid rgb(42, 186, 44);
  padding: 8px 36px;
  border-radius: 30px;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 13px;
  color: #111111;
  background-color: #73dd77;
  margin-top: 30px;
}

.reservation .container .banner .reservation_form_box form button h6 {
  font-size: 20px;
  color: white;
}

.reservation .container .banner .reservation_form_box form button:active {
  background-color: #3e8e41;
  transform: scale(1.03);
}

.reservation .container .banner .reservation_form_box form button span {
  margin-left: 1rem;
  padding: 3px;
  background: #111;
  border-radius: 100%;
  color: #fff;
}

@media (max-width: 1520px) {
  .reservation .container {
    min-width: 100%;
  }
}

@media (max-width: 1040px) {
  .reservation .container .banner {
    padding: 20px;
  }
}

@media (max-width: 1350px) {
  .reservation .container .banner img {
    width: 42vw;
  }
}

@media (max-width: 885px) {
  .reservation .container {
    flex-direction: column;
    gap: 30px;
  }

  .reservation .container .banner img {
    width: 70vw;
  }

  .reservation .container .banner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 660px) {
  .reservation .container .banner img {
    width: 80%;
  }

  .reservation .container .banner .reservation_form_box form div {
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  .reservation .container .banner .reservation_form_box h1 {
    font-size: 1.5rem;
  }

  .reservation .container .banner .reservation_form_box p {
    font-size: 16px;
  }
}
