.wrapper {
  box-sizing: border-box;
  height: 90vh;
  padding: 25px 50px 50px 50px;

}

/* Ensure images are responsive */
.wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap img {
  width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 60px;
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .wrapper {
      padding: 20px 40px 40px 40px;
  }
}

@media (max-width: 992px) {
  .wrapper {
      padding: 15px 30px 30px 30px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    height: 45vh;
      margin-top: -50px;
  }

  .wrap img {
      border-radius: 50px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    height: 20px;
  }

  .wrap img {
      border-radius: 40px;
  }
}
